export const DOWNLOAD_PROGRESS_DIALOG_STYLE = theme => ({
  downloadProgressDialog: {
    position: 'fixed',
    bottom: 0,
    right: 30,
    zIndex: 500,
    minWidth: 300
  }
})

export const DOWNLOAD_PROGRESS_DIALOG_HEADER_STYLE = theme => ({
  downloadProgressDialogHeader: {
    backgroundColor: 'rgb(50, 50, 50)',
    color: 'rgb(240, 240, 240)',
    paddingLeft: 30,
    paddingRight: 20,
    height: 40
  }
})
